import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Popover } from 'reactstrap';
import { ERROR, eventManager, EventType, showToast } from 'summer';
import { useFavoritosRecentes } from '../../../hooks/useFavoritosRecentes';
import { useKeyboardNavigation } from '../../../hooks/useKeyboardNavigation';
import { useQuickAccess } from '../../../hooks/useQuickAccess';
import { removerFavorito } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import { getMenuItem, ordenarModulos } from '../../../utilities/menu.util';
import QuickAccessList from './QuickAccessList';

interface QuickAccessRootProps {
  open: boolean;
  config: any;
  items: any[];
}

const QuickAccessRoot: FC<QuickAccessRootProps> = ({ open, config, items }) => {
  const { globalParameter, currentState, accessOldHonorarios } = useSelector<Reducers, GlobalState>(
    state => state.globalReducer
  );

  const quickAccess = useQuickAccess();
  const favsRecs = useFavoritosRecentes();

  const [listItems, setListItems] = useState([]);

  useKeyboardNavigation(
    {
      enableLeftRight: true,
      selector: '.quick-access-popover',
    },
    [open]
  );

  useEffect(
    () => {
      const map: any = {};

      items.forEach(it => {
        if (Object.keys(map).indexOf(it.modulo) === -1) {
          map[it.modulo] = [];
        }
        map[it.modulo].push(it);
      });

      const mhoAsNitrus = globalParameter.acesso.temAcessoAoNitrus && accessOldHonorarios;

      const result = ordenarModulos(Object.keys(map).map(it => getMenuItem({ modulo: it, itens: map[it] }, mhoAsNitrus)));

      if (quickAccess.isSingle) {
        setListItems(result.filter(it => it.modulo === currentState));
      } else {
        setListItems(result);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  useEffect(
    () => {
      if (!open && quickAccess.removeFavList.length) {
        removerFavorito(globalParameter.usuario.id, quickAccess.removeFavList, {
          errorFunction: mensagem => showToast(mensagem, ERROR),
          thenFunction: telas => {
            favsRecs.update();
            telas.forEach(it => eventManager.emit(EventType.FAV_RESULT, it.modulo, it.itemMenu, false));
          },
        });
        quickAccess.clear();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  const revert = () => quickAccess.clear();

  return (
    <>
      <Button className={cx('quick-access', { active: open })} color="link" size="sm" id={config.id}>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon className="mr-2" icon={config.icon as IconProp} />
          <span>{config.label}</span>
          <FontAwesomeIcon className="ml-2" icon={['fas', open ? 'angle-up' : 'angle-down']} />
        </div>
      </Button>
      <Popover
        id={`popover-${config.id}`}
        target={config.id}
        className="quick-access-popover popover-custom-wrapper"
        placement="auto"
        container="body"
        trigger="legacy"
        isOpen={open}
        toggle={quickAccess.toggle}
      >
        <div className="quick-access-wrapper">
          {listItems.length === 0 && (
            <h4 className="empty">Você ainda não possui telas {quickAccess.isFav ? 'adicionadas aos seus favoritos' : 'acessadas recentemente'}</h4>
          )}
          <div className={cx('items', { single: listItems.length === 1 })}>
            {listItems.map((item, index) => (
              <QuickAccessList item={item} key={index} />
            ))}
          </div>
          {quickAccess.isFav && quickAccess.removeFavList.length > 0 && (
            <footer>
              <span>
                Você apagou {quickAccess.removeFavList.length} favorito{quickAccess.removeFavList.length === 1 ? '' : 's'}.
                <button onClick={revert}>
                  Clique aqui para desfazer <FontAwesomeIcon icon={['fas', 'undo']} />
                </button>
              </span>
            </footer>
          )}
        </div>
      </Popover>
    </>
  );
};

export default QuickAccessRoot;
