// Evento que decide a formatação da tela.
export const STATE_EVT_TABELA = 'stateEvtTabela';
export const STATE_EVT_NPERIODICOS = 'stateEvtNperiodicos';

// Mensagens
export const MSG_STATUS_EVENTO_PENDENTE = 'Pendente para Envio';
export const MSG_EVENTO_PENDENTE =
  'Um evento pendente foi adicionado para envio no monitor de eventos do eSocial. Para mais detalhes ' +
  'acesse: Módulo Folha de Pagamento > eSocial > Exportação > Monitor de eventos.' +
  "<ToastRedirect path='/mfp/monitorEventoEsocialProcessView'/>";
export const MSG_EVENTO_PENDENTE_RETIFICACAO =
  'Um evento pendente de RETIFICAÇÃO foi adicionado para envio no monitor de eventos do eSocial. Para mais detalhes ' +
  'acesse: Módulo Folha de Pagamento > eSocial > Exportação > Monitor de eventos.' +
  "<ToastRedirect path='/mfp/monitorEventoEsocialProcessView'/>";
export const ALTERACAO_EVENTO_PENDENTE_MULTI_EMPRESA =
  'Foi alterada uma informação que é compartilhada por todas as empresas no sistema e essa informação foi utilizada para criação dos eventos do eSocial' +
  ' e deverá ser atualizada. Não se preocupe foi criado um Evento Pendente para cada empresa que utilizou essa informação.';

export const DESEJA_GERAR_RELATORIO_EVENTOS_PENDENTES = '\nDeseja gerar um relatório com os detalhes das Empresas afetadas?';
export const INCLUSAO_EVENTO_PENDENTE_MULTI_EMPRESA =
  'Um Evento Pendente foi adicionado para envio ao eSocial para cada Empresa que está configurada para o mesmo.';

// Eventos de Tabela
export const INCLUSAO_S1000 = 'Os dados do Empregador %s foram alterados pelo usuário %s';
export const ALTERACAO_S1000 = 'Os dados do estabelecimento %s foram alterados pelo usuário %s';
export const ALTERACAO_S1005 = 'Os dados do Tomador %s foram alterados na Empresa %s pelo usuário %s';
export const INCLUSAO_S1010 = 'O Evento %s foi incluído na empresa %s pelo usuário %s';
export const ALTERACAO_S1010 = 'Os dados do Evento %s foram alterados na empresa %s pelo usuário %s';
export const ALTERACAO_EMPRESA_S1020 = 'Os dados da Lotação tributaria da empresa %s foram alterados pelo usuário %s';

// Eventos Periódicos
export const ALTERACAO_S2205 = 'Os dados cadastrais do Vinculo %s - %s foram alterados pelo usuário %s';
export const INCLUSAO_DEPENDENTE_S2205 = 'Novo Dependente incluído para o vínculo %s - %s pelo usuário %s';
export const ALTERACAO_S2206 = 'Os dados do contrato de trabalho do Vínculo %s - %s foram alterados pelo usuário %s';
export const ALTERACAO_RETORNO_S2230 = 'Término do Afastamento Temporário do Vínculo %s - %s incluido pelo usuário %s';

export const ESOCIAL_SEM_CONFIG_MSG =
  'Existem novas configurações do eSocial que precisam ser feitas para o envio. Para configurar acesse:' +
  " \nMódulo Folha de Pagamento > eSocial > Exportação > eSocial > 'Botão Configuração'";
export const ESOCIAL_SEM_RESPONSAVEL_CONFIG_MSG =
  'É necessário selecionar o responsável pelas informações da Folha de Pagamento do eSocial. Para configurar acesse:' +
  " \nMódulo Folha de Pagamento > eSocial > Exportação > eSocial > 'Botão Configuração'";
export const GERAR_S1299_PENDENTE_QUESTION =
  'Você deseja gerar o evento pendente\n(S-1299 - Fechamento dos Eventos Periódicos) do eSocial para a(s) empresa(s) selecionada(s)?';
export const GERAR_S1298_PENDENTE_QUESTION =
  'Você deseja gerar o evento pendente\n(S-1298 - Reabertura dos Eventos Periódicos) do eSocial para a(s) empresa(s) selecionada(s)?';
export const MSG_AMBIENTE_DE_TESTES =
  'O tipo de ambiente selecionado <span style="color: #FF0000;"><b>não é oficial</b></span> e é usado somente para testes. ' +
  'Os dados gerados não são considerados como valores oficiais para o eSocial. Deseja continuar?';
export const MENSAGEM_A3_APP_DESKTOP = 'A Utilização do eSocial com Certificado Digital A3 só está disponível pelo Calima Apps Desktop.';
export const MENSAGEM_OPERACAO_CANCELADA = 'A operação de geração do evento pendente foi cancelada. Não será gerado evento pendente para o eSocial';

export const E_SOCIAL_MESSAGE_ENUM_VALUES = [
  STATE_EVT_TABELA,
  STATE_EVT_NPERIODICOS,
  MSG_STATUS_EVENTO_PENDENTE,
  MSG_EVENTO_PENDENTE,
  ALTERACAO_EVENTO_PENDENTE_MULTI_EMPRESA,
  DESEJA_GERAR_RELATORIO_EVENTOS_PENDENTES,
  INCLUSAO_EVENTO_PENDENTE_MULTI_EMPRESA,
  INCLUSAO_S1000,
  ALTERACAO_S1000,
  ALTERACAO_S1005,
  INCLUSAO_S1010,
  ALTERACAO_S1010,
  ALTERACAO_EMPRESA_S1020,
  ALTERACAO_S2205,
  INCLUSAO_DEPENDENTE_S2205,
  ALTERACAO_S2206,
  ALTERACAO_RETORNO_S2230,
  ESOCIAL_SEM_CONFIG_MSG,
  ESOCIAL_SEM_RESPONSAVEL_CONFIG_MSG,
  GERAR_S1299_PENDENTE_QUESTION,
  GERAR_S1298_PENDENTE_QUESTION,
  MSG_AMBIENTE_DE_TESTES,
  MENSAGEM_A3_APP_DESKTOP,
];

export const generateMessage = (template, ...rest) => {
  let i = 0;
  return template.replace(/%s/g, () => {
    const j = i;
    i++;
    return rest.slice(j, i);
  });
};
