import { MenuItem } from '../../../models/dtos/ger/menuItem';

const PLANO_CONTA = 'Plano de contas';
const CENTRO_CUSTO = 'Centro de custo';
const LANCAMENTOS = 'Lançamento';

const mcgMenu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/mcg/dashboard',
  },
  {
    content: [
      {
        content: [
          {
            label: 'Normal',
            to: '/mcg/lancamentoSaldoSearchView',
          },
          {
            label: 'Simplificado',
            to: '/mcg/lancamentoSaldoSimplificadoProcessView',
          },
        ],
        label: LANCAMENTOS,
      },
      {
        content: [
          {
            label: 'Históricos',
            to: '/mcg/grupoHistoricoSearchView',
          },
          {
            label: PLANO_CONTA,
            to: '/mcg/grupoPlanoContaSearchView',
          },
          {
            label: CENTRO_CUSTO,
            to: '/mcg/grupoCentroCustoSearchView',
          },
        ],
        label: 'Grupos',
      },
      {
        label: 'Histórico',
        to: '/mcg/historicoSearchView',
      },
      {
        content: [
          {
            label: PLANO_CONTA,
            to: '/mcg/planoContaSearchView',
          },
          {
            label: 'Plano de conta referencial',
            to: '/mcg/planoContaReferencialSearchView',
          },
          {
            label: 'Plano de conta instituição',
            to: '/mcg/planoContaMCGReferencialInstituicaoSearchView',
          },
        ],
        label: PLANO_CONTA,
      },
      {
        label: CENTRO_CUSTO,
        to: '/mcg/centroCustoSearchView',
      },
      {
        label: 'Notas explicativas',
        to: '/mcg/notaExplicativaReferenciaSearchView',
      },
      {
        content: [
          {
            label: 'Apuração com Base no Lucro Presumido (Registros P200, P300, P400, P500)',
            to: '/mcg/configECFIrpjCsllSearchView',
          },
          {
            label: 'Origem e Aplicação de Recursos – Imunes e Isentas (Registro X390)',
            to: '/mcg/configECFImuneIsentaSearchView',
          },
          {
            label: 'Quadro Societário – Imunes e Isentas (Registro Y612)',
            to: '/mcg/configECFY612SearchView',
          },
          {
            content: [
              {
                label: 'Informar composição de custos',
                to: '/mcg/configComposicaoCustosSearchView',
              },
              {
                label: 'Vincular contas',
                to: '/mcg/vincularContasComposicaoCustosTreeView',
              },
            ],
            label: 'Informativo da composição de custos (Registro L210)',
          },
        ],
        label: 'SPED ECF',
      },
      {
        label: 'Classificação DRE - Demonstração do Resultado do Exercício',
        to: '/mcg/contaResultadoMCGTreeView',
      },
      {
        label: 'Classificação DFC - Demonstração de Fluxo Caixa - Direto',
        to: '/mcg/contaFluxoCaixaDiretoTreeView',
      },
      {
        label: 'Classificação DFC - Demonstração de Fluxo Caixa - Indireto',
        to: '/mcg/contaFluxoCaixaIndiretoTreeView',
      },
      {
        label: 'Classificação AF - Análise Financeira',
        to: '/mcg/analiseFinanceiraConfigView',
      },
      {
        label: 'Classificação DMPL - Demonstração de Mutações do Patrimônio',
        to: '/mcg/mutacaoPatrimonioLiquidoTreeView',
      },
      {
        label: 'Classificação DVA - Demonstração do valor adicionado',
        to: '/mcg/demostracaoValorAdicionadoTreeView',
      },
      {
        content: [
          {
            label: 'Lançamento automático',
            to: '/mcg/lucroPrejuizoClassificacaoSearchView',
          },
          {
            label: 'Lançamento manual',
            to: '/mcg/lucroPrejuizoSearchView',
          },
        ],
        label: 'Classificação DLPA - Demonstração de Lucros e Prejuízos Acumulados',
      },
      {
        content: [
          {
            label: PLANO_CONTA,
            to: '/mcg/saldoAnteriorPlanoContaSearchView',
          },
          {
            label: CENTRO_CUSTO,
            to: '/mcg/saldoAnteriorCentroCustoSearchView',
          },
          {
            label: 'Mudança do plano de contas (Registro I157 SPED ECD)',
            to: '/mcg/saldoAnteriorRegistroECDSearchView',
          },
        ],
        label: 'Saldos anteriores',
      },
      {
        label: 'Lançamento padrão',
        to: '/mcg/lancamentoPadraoSearchView',
      },
      {
        content: [
          {
            label: `${PLANO_CONTA} - Banco`,
            to: '/mcg/bancoExtratoPlanoContaSearchView',
          },
          {
            label: 'Customização de termos e documentos',
            to: '/mcg/customizacaoTermoSearchView',
          },
          {
            label: 'Doações a campanhas eleitorais',
            to: '/mcg/doacaoPartidoPoliticoSearchView',
          },
          {
            label: 'Sociedades em Conta de Participação (SCP)',
            to: '/mcg/scpSearchView',
          },
        ],
        label: 'Tabelas',
      },
      {
        content: [
          {
            label: 'Empresa',
            to: '/mcg/configEmpresaMCGEditView',
          },
          {
            label: LANCAMENTOS,
            to: '/mcg/lancamentoMCGConfigSearchView',
          },
          {
            label: 'Livro/Folha/Nº de ordem',
            to: '/mcg/livroFolhaEditView',
          },
          {
            label: 'Quadro societário',
            to: '/mcg/quadroSocietarioSearchView',
          },
        ],
        label: 'Configurações',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Apuração',
        to: '/mcg/lalurLacsApuracaoProcessView',
      },
      {
        label: 'Configuração',
        to: '/mcg/lalurLacsConfigProcessView',
      },
    ],
    icon: 'pe-7s-note2',
    label: 'Lucro Real',
  },
  {
    content: [
      {
        label: 'Imóvel rural',
        to: '/mcg/imovelRuralSearchView',
      },
      {
        label: 'Terceiros',
        to: '/mcg/terceiroSearchView',
      },
      {
        label: `${PLANO_CONTA} - Banco`,
        to: '/mcg/bancoExtratoPlanoContaSearchView',
      },
    ],
    icon: 'pe-7s-id',
    label: 'LCDPR',
  },
  {
    content: [
      {
        label: 'Conciliação contábil - Lançamentos',
        to: '/mcg/conciliacaoContasView',
      },
      {
        content: [
          {
            label: 'Conciliação bancária',
            to: '/mcg/conciliacaoBancariaView',
          },
          {
            label: `${PLANO_CONTA} - Histórico`,
            to: '/mcg/historicoExtratoPlanoContaSearchView',
          },
          {
            label: 'Importação extrato (Formato OFX)',
            to: '/mcg/importacaoExtratoBancarioView',
          },
        ],
        label: 'Conciliação bancária - Extratos e lançamentos',
      },
      {
        label: 'Verificar erros/avisos',
        to: '/mcg/verificarErroMCGProcessView',
      },
      {
        content: [
          {
            label: 'Módulo Fiscal',
            to: '/mcg/integracaoFiscalContabilProcessView',
          },
          {
            label: 'Módulo Controle Patrimonial',
            to: '/mcg/integracaoMCPProcessView',
          },
          {
            label: 'Módulo Folha de Pagamento',
            to: '/mcg/integracaoFolhaPagamentoProcessView',
          },
          {
            label: 'Módulo Honorários',
            to: '/mcg/integracaoHonorariosProcessView',
          },
        ],
        label: 'Integração',
      },
      {
        content: [
          {
            label: 'Outro histórico referencial dos existentes',
            to: '/mcg/importacaoHistoricoProcessView',
          },
          {
            label: 'Outros planos de contas/plano referencial dos existentes',
            to: '/mcg/importacaoPlanoContaProcessView',
          },
        ],
        label: 'Importação',
      },
      {
        label: 'Encerrar conta de resultado',
        to: '/mcg/encerrarContasResultadoProcessView',
      },
      {
        label: 'Transferência de lançamentos entre contas',
        to: '/mcg/transferenciaLancamentoContaProcessView',
      },
      {
        label: 'Fechamento/Restauração',
        to: '/mcg/fechamentoRestauracaoMCGProcessView',
      },
    ],
    icon: 'pe-7s-play',
    label: 'Processos',
  },
  {
    content: [
      {
        content: [
          {
            label: 'SPED ECF - Escrituração Contábil Fiscal',
            to: '/mcg/spedECFExportacaoProcessView',
          },
          {
            label: 'SPED ECD - Escrituração Contábil Digital',
            to: '/mcg/geracaoSpedECDProcessView',
          },
          {
            label: 'MANAD - Secretaria Receita Previdenciária (SRP)',
            to: '/mcg/exportacaoManadMCGProcessView',
          },
          {
            label: 'FCONT - Controle Fiscal Contábil de Transição',
            to: '/mcg/exportacaoFcontProcessView',
          },
          {
            label: 'LCDPR - Livro Caixa Digital do Produtor Rural',
            to: '/mcg/lcdprExportacaoProcessView',
          },
        ],
        label: 'Exportação',
      },
      {
        content: [
          {
            label: 'Históricos - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mcg/importacaoHistoricoCSVProcessView',
          },
          {
            label: 'Lançamentos - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mcg/importacaoLancamentosCSVProcessView',
          },
          {
            label: 'Lançamentos padrões - Planilha do Excel (Formato CSV, XLS e XLSX)',
            to: '/mcg/importacaoLancamentoPadraoCSVProcessView',
          },
          {
            label: `${PLANO_CONTA} - Planilha do Excel (Formato CSV, XLS e XLSX)`,
            to: '/mcg/importacaoPlanoContaCSVProcessView',
          },
          {
            label: 'SPED ECD - Escrituração Contábil Digital',
            to: '/mcg/importacaoSpedContabilProcessView',
          },
          {
            label: 'Leiaute contábil',
            to: '/mcg/importacaoLancamentoMCGView',
          },
        ],
        label: 'Importação',
      },
    ],
    icon: 'pe-7s-folder',
    label: 'Arquivos digitais',
  },
  {
    content: [
      {
        content: [
          {
            label: 'Normal',
            to: '/mcg/balanceteNormalReportView',
          },
          {
            label: 'Normal anual',
            to: '/mcg/balanceteNormalAnualReportView',
          },
          {
            label: 'Caixa',
            to: '/mcg/balanceteCaixaReportView',
          },
          {
            label: CENTRO_CUSTO,
            to: '/mcg/balanceteCentroCustoReportView',
          },
          {
            label: 'Comparativo',
            to: '/mcg/balanceteComparativoReportView',
          },
        ],
        label: 'Balancete',
      },
      {
        content: [
          {
            label: 'Balanço de abertura',
            to: '/mcg/balancoAberturaReportView',
          },
          {
            label: 'Balanço patrimonial',
            to: '/mcg/balancoPatrimonialReportView',
          },
          {
            label: CENTRO_CUSTO,
            to: '/mcg/balancoCentroCustoReportView',
          },
        ],
        label: 'Balanço',
      },
      {
        content: [
          {
            label: 'DFC - Demonstração do Fluxo de Caixa - Direto',
            to: '/mcg/contaFluxoCaixaReportView',
          },
          {
            label: 'DFC - Demonstração do Fluxo de Caixa - Indireto',
            to: '/mcg/contaFluxoCaixaIndiretoReportView',
          },
          {
            label: 'Livro Caixa - Normal',
            to: '/mcg/caixaNormalReportView',
          },
          {
            label: 'Livro Caixa - Simplificado',
            to: '/mcg/caixaSimplificadoReportView',
          },
          {
            label: 'Boletim Diário de Caixa',
            to: '/mcg/boletimDiarioCaixaReportView',
          },
        ],
        label: 'Caixa',
      },
      {
        content: [
          {
            label: "Parte 'A'",
            to: '/mcg/lalurLacsParteAReportView',
          },
          {
            label: "Parte 'B'",
            to: '/mcg/lalurLacsParteBReportView',
          },
        ],
        label: 'Lucro Real',
      },
      {
        content: [
          {
            label: 'Normal',
            to: '/mcg/razaoNormalReportView',
          },
          {
            label: CENTRO_CUSTO,
            to: '/mcg/razaoCentroCustoReportView',
          },
          {
            label: 'Eventual/Sequencial',
            to: '/mcg/razaoEventualReportView',
          },
        ],
        label: 'Razão',
      },
      {
        content: [
          {
            label: CENTRO_CUSTO,
            to: '/mcg/centroCustoReportView',
          },
          {
            label: 'Históricos',
            to: '/mcg/historicoReportView',
          },
          {
            label: LANCAMENTOS,
            to: '/mcg/lancamentoReportView',
          },
          {
            label: 'Lançamento padrão',
            to: '/mcg/lancamentosPadraoReportView',
          },
          {
            label: PLANO_CONTA,
            to: '/mcg/planoContaReportView',
          },
          {
            label: `${PLANO_CONTA} referencial`,
            to: '/mcg/planoContaRefReportView',
          },
          {
            label: 'Histórico de plano de contas',
            to: '/mcg/historicoPlanoContaReportView',
          },
        ],
        label: 'Listagem',
      },
      {
        content: [
          {
            label: 'DRE - Demonstração do Resultado do Exercício',
            to: '/mcg/demonstracaoResultadoReportView',
          },
          {
            label: 'DRE - Demonstração do Resultado do Exercício Anual',
            to: '/mcg/demonstracaoResultadoAnualReportView',
          },
          {
            label: 'Centro de custo',
            to: '/mcg/DemonstracaoResultadoCentroCustoReportView',
          },
        ],
        label: 'Demonstração do Resultado do Exercício',
      },
      {
        content: [
          {
            label: 'AF - Análise Financeira',
            to: '/mcg/analiseFinanceiraReportView',
          },
          {
            label: 'Centro Custo',
            to: '/mcg/analiseFinanceiraCentroCustoReportView',
          },
        ],
        label: 'Análise Financeira',
      },
      {
        content: [
          {
            label: 'Diário',
            to: '/mcg/diarioReportView',
          },
          {
            calimaPro: true,
            label: 'Centro Custo',
            to: '/mcg/diarioCentroCustoReportView',
          },
        ],
        label: 'Diário',
      },
      {
        label: 'Conta de resultado classificada',
        to: '/mcg/contaResultadoClassificadaReportView',
      },
      {
        label: 'DLPA - Demonstração dos Lucros e Prejuízos Acumulados',
        to: '/mcg/demonstrativoLucroPrejuizoReportView',
      },
      {
        label: 'DMPL - Demonstração de Mutações do Patrimônio Líquido',
        to: '/mcg/demonstracaoMutacoesPatrimonioLiquidoReportView',
      },
      {
        label: 'DVA - Demonstração do Valor Adicionado ',
        to: '/mcg/demonstracaoValorAdicionadoReportView',
      },
      {
        label: 'Receita e despesa',
        to: '/mcg/receitaDespesasReportView',
      },
      {
        label: 'Termo de abertura e encerramento',
        to: '/mcg/termoAberturaEncerramentoReportView',
      },
      {
        label: 'Declaração de veracidade',
        to: '/mcg/declaracaoVeracidadeReportView',
      },
      {
        label: 'Carta de responsabilidade',
        to: '/mcg/cartaResponsabilidadeReportView',
      },
      {
        label: 'Listagem de movimentações de histórico padrão',
        to: '/mcg/historicoPadraoReportView',
      },
      {
        label: 'Sócio e participação',
        to: '/mcg/socioParticipacaoReportView',
      },
      {
        label: 'Conciliação bancária',
        to: '/mcg/conciliacaoBancariaReportView',
      },
      {
        label: `Relacionamento de ${PLANO_CONTA}`,
        to: '/mcg/planoContaRelacionamentoReportView',
      },
    ],
    icon: 'pe-7s-print',
    isReportList: true,
    label: 'Relatórios',
  },
  {
    content: [
      {
        label: 'Evolução de conta contábil',
        to: '/mcg/evolucaoContaContabilGrafView',
      },
      {
        label: 'Composição de receitas e despesas',
        to: '/mcg/composicaoReceitaDespesasGrafView',
      },
    ],
    icon: 'pe-7s-graph1',
    label: 'Gráficos',
  },
];

export default mcgMenu;
