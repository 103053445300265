import React from 'react';
import { add, alertManager, AlertProps, AlertType, currentDate, ERROR, format, formatRef, INFORMATION, progressActions, showToast } from 'summer';
import { InformacaoEnvioA3 } from '../models/dtos/ger/InformacaoEnvioA3';
import { Cat } from '../models/dtos/mfp/cat';
import { CondicaoAmbientalTrabalho } from '../models/dtos/mfp/condicaoAmbientalTrabalho';
import { ESocialEventoPendente } from '../models/dtos/mfp/eSocialEventoPendente';
import { GeracaoEventoPendenteParam } from '../models/dtos/mfp/geracaoEventoPendenteParam';
import { ProcessoTrabalhista } from '../models/dtos/mfp/processoTrabalhista';
import { TributosProcessoTrabalhista } from '../models/dtos/mfp/tributosProcessoTrabalhista';
import { VinculoExameOcupacional } from '../models/dtos/mfp/vinculoExameOcupacional';
import { MSG_EVENTO_PENDENTE, MSG_EVENTO_PENDENTE_RETIFICACAO } from '../models/enumerated/ger/eSocialMessageUtil';
import {
  ABRIL,
  AGOSTO,
  DEZEMBRO,
  FEVEREIRO,
  JANEIRO,
  JULHO,
  JUNHO,
  MAIO,
  MARCO,
  NOVEMBRO,
  OUTUBRO,
  SETEMBRO,
} from '../models/enumerated/mlf/mesEnum';
import { checkUsuarioVisualizouTour, salvarUsuarioVisualizouTour } from '../services/ger.service';
import { deleteEventosPendentesESocial, gerarEvtPendenteS2230 } from '../services/mfp.service';
import { LOCAL_STORAGE_SOCKET_HASH } from './constantes';

export const MSG_AMBIENTE_DE_TESTES =
  'O tipo de ambiente selecionado não é oficial e é usado somente para testes. Os dados gerados não são considerados como valores oficiais para o eSocial. Deseja continuar?';

export const MGS_LOTE_50_EVENTOS =
  'Quando enviado mais de 50 eventos, o envio será feito em partes e simultaneamente um após o outro aguarde até a conclusão de todo o processo.';

export const MGS_INCOMPATIBILIDADE_AFASTAMENTO =
  'Existem vínculos selecionados que estão afastados durante o período de obrigatoriedade e possuem afastamentos que não tem ' +
  'compatibilidade com o eSocial, portanto não serão geradas as informações de afastamento.';

export const MSG_EMPRESA_NAO_CONFIGURADA =
  'A empresa selecionada não possui configuração do eSocial. Configure em: MFP > eSocial > Configurações > Geral';

export const MSG_ATRES_DESCONTINUADO =
  'O aplicativo do assinador A3 será descontinuado em breve. Recomendamos que ' +
  'adquira um certificado A1 para essa empresa. Acesse o site e obtenha um certificado digital A1 com um ' +
  'valor diferenciado!';

export const DIA_LIMITE_ENVIO_DECIMO_TERCEIRO = 20;
export const ITEM_TOUR_MSG_ATRES_DESCONTINUADO = 'MENSAGEM_A3_DESCONTINUADO';

export const getDiaLimiteEnvioPeriodicos = () => {
  const diasParaEnvioMap = new Map<number, number>();
  diasParaEnvioMap.set(JANEIRO.value, 15);
  diasParaEnvioMap.set(FEVEREIRO.value, 15);
  diasParaEnvioMap.set(MARCO.value, 15);
  diasParaEnvioMap.set(ABRIL.value, 15);
  diasParaEnvioMap.set(MAIO.value, 15);
  diasParaEnvioMap.set(JUNHO.value, 14);
  diasParaEnvioMap.set(JULHO.value, 15);
  diasParaEnvioMap.set(AGOSTO.value, 15);
  diasParaEnvioMap.set(SETEMBRO.value, 13);
  diasParaEnvioMap.set(OUTUBRO.value, 15);
  diasParaEnvioMap.set(NOVEMBRO.value, 14);
  diasParaEnvioMap.set(DEZEMBRO.value, 13);
  return diasParaEnvioMap.get(currentDate().getMonth() + 1);
};

export const getToolTipFmt = item => {
  const result = [];

  if (item.extraFields && item.extraFields.codigoSefip) {
    result.push(`Código SEFIP: ${item.extraFields.codigoSefip}`);
  }

  if (item.extraFields && item.extraFields.codigoEsocial) {
    result.push(`Código eSocial: ${item.extraFields.codigoEsocial}`);
  }

  return result.join(' / ');
};

export const showLoading = () =>
  progressActions.showProgress({
    animated: true,
    className: 'progress-bar-rounded progress-animated-alt',
    color: 'first',
    label: 'Aguarde processando...',
    striped: true,
    value: 100,
  });

export const promisedAlert = (message, type = AlertType.WARNING_YES_NO) =>
  new Promise(resolve => {
    promisedAlertWithCatch(message, type)
      .then(resolve)
      .catch(() => null);
  });

export const promisedAlertWithCatch = (message, type = AlertType.WARNING_YES_NO) =>
  new Promise((resolve, reject) => {
    alertManager.emit({
      message,
      onCancelClick: () => reject(),
      onNoClick: () => reject(),
      onOkClick: () => resolve(),
      type,
    });
  });

export const closeLoading = () => progressActions.hideProgress();

export const propsEvtPendenteTrabalhador = [
  'cpf',
  'identidade',
  'identidadeOrgaoExpedidor',
  'identidadeDataEmissao',
  'cnhNumero',
  'cnhCategoria',
  'nome',
  'nomeMae',
  'nomePai',
  'deficiencia',
  'carteiraTrabalhoNumero',
  'carteiraTrabalhoSerie',
  'carteiraTrabalhoUF',
  'sexo',
  'raca',
  'estadoCivil',
  'grauInstrucao',
  'dataNascimento',
  'naturalidadeCidade',
  'naturalidadeUF',
  'carteiraTrabalhoUF',
];

export const subPropsEvtPendenteTrabalhador = ['codigoMunicipio', 'sigla'];

export const propsEvtPendenteTrabalhadorEnd = ['cidade', 'titulo', 'logradouro', 'numero', 'complemento', 'bairro', 'cep', 'uf'];

export const subsubPropsEvtPendenteTrabalhadorEnd = ['sigla', 'bairro', 'nome', 'codigoMunicipio'];

export const propsEvtPendenteVinculo = [
  'trabalhadorRural',
  'sindicato',
  'filiadoSindicado',
  'vinculoCargoAtual',
  'categoria',
  'salarioAtual',
  'contratoTrabalho',
  'dataDemissaoDeterminado',
  'quadroHorarioAtual',
  'jornadaTrabalhoParcial',
  'tipoRegimeJornadaTrabalho',
];
export const subPropsEvtPendenteVinculo = ['id', 'valor', 'horista', 'quadroHorario'];

export const propsEvtPendenteTrabDomestico = ['cidade', 'logradouro', 'numero', 'complemento', 'bairro', 'cep', 'uf'];

export const subPropsEvtPendenteTrabDomestico = ['codigoMunicipio', 'bairro', 'sigla'];

export const propsEvtPendenteDep = ['nome', 'dtNascimento', 'cpf', 'dependenteIRPJ', 'dependeteSalarioFamilia', 'incapacidadeFisicaMentalTrabalho'];

export const isAlteradoEsocial = (objectBD, ObjectUpdated, campos, subCampos): boolean => {
  let result = false;
  if (objectBD && ObjectUpdated) {
    result = campos.some(value => {
      const propertyBD = Object.getOwnPropertyDescriptor(objectBD, value);
      const propertyUpdated = Object.getOwnPropertyDescriptor(ObjectUpdated, value);
      let isTrue = false;
      if (propertyBD && propertyUpdated) {
        const isValidObj = isValidObject(propertyBD, propertyUpdated);
        if (isValidObj) {
          if (isAlteradoEsocial(propertyBD.value, propertyUpdated.value, subCampos, subCampos)) {
            isTrue = true;
          }
        } else if (propertyBD.value !== propertyUpdated.value) {
          isTrue = true;
        }
      }
      return isTrue;
    });
  } else if (objectBD !== ObjectUpdated) {
    result = true;
  }
  return result;
};

const isValidObject = (objBD, objUpdt): boolean => {
  return objBD.value && objUpdt.value && typeof objBD.value === 'object';
};

/**
 * Argumentos para passagem de parâmetros para o .exe
 * @param numeroProtocolo => Nº do protocolo a ser consultado
 * @param tipoProcesso => (0 - EnvioLote, 1 - ConsultaProtocolo, 2 - Sincronização de eventos)
 * @param url => Url do Calima
 * @param grupoEventos => (1 - Iniciais, 2 - NaoPeriodicos, 3 - Periodicos)
 * @param tipoAmbiente => (0 - Producao, 1 - Homologacao)
 * @param empresaKey => Key que representa o path dos arquivos (idEmpresa-tipoAmbiente-dataHoraGeracao)
 * @param versaoCalima => Versão Calima
 * @param tipoProjeto => Tipo de Projeto: esocial, reinf, esocialBx.
 * @return
 */
export const generateArguments = (
  numeroProtocolo: string = '',
  tipoProcesso: string = '0',
  url: string = '',
  grupoEventos: string = '1',
  tipoAmbiente: string = '1',
  empresaKey: string = '',
  versaoCalima: string = '',
  tipoProjeto: string = ''
): InformacaoEnvioA3 => {
  const informacaoEnvioA3DTO: InformacaoEnvioA3 = {
    empresaKey,
    socketHash: getSocketHash(),
    tipoAmbiente,
    tipoProcesso,
    tipoProjeto,
    url,
    versaoCalima,
  };

  informacaoEnvioA3DTO.grupoEventos = grupoEventos;
  informacaoEnvioA3DTO.numeroProtocolo = numeroProtocolo;

  return informacaoEnvioA3DTO;
};

const getSocketHash = (): string => {
  return sessionStorage.getItem(LOCAL_STORAGE_SOCKET_HASH);
};

const getMessageErrorEvtPendente = evento => {
  alertManager.emit({
    message: `Não foi possível enviar o evento ${evento}, verifique se a empresa já enviou os eventos de tabelas (S-1000) e/ou os eventos não periodicos (S-2200/S-2300), verifique também se já não existe um evento pendente para o(s) vínculo(s) em: Módulo Folha de Pagamento > eSocial > Exportação > Monitor de Eventos eSocial.`,
    type: AlertType.INFORMATION,
  });
};

type DTO = Cat | CondicaoAmbientalTrabalho | VinculoExameOcupacional | ProcessoTrabalhista | TributosProcessoTrabalhista;

export const gerarEvtPendente = (funcao, dto: DTO, evento: string) => {
  funcao(dto, {
    errorFunction: () => {
      getMessageErrorEvtPendente(evento);
    },
    thenFunction: (result: ESocialEventoPendente) => {
      if (result) {
        showToast(result.retificado ? MSG_EVENTO_PENDENTE_RETIFICACAO : MSG_EVENTO_PENDENTE, INFORMATION);
      } else {
        getMessageErrorEvtPendente(evento);
      }
    },
  });
};

export const gerarEvtPendenteAfastamentoTemporario = (geracaoEventoPendenteParam: GeracaoEventoPendenteParam, isAfastamento = true) => {
  gerarEvtPendenteS2230(geracaoEventoPendenteParam, {
    errorFunction: mensagem => {
      showToast(mensagem, ERROR);
    },
    thenFunction: result => {
      if (result) {
        showToast(MSG_EVENTO_PENDENTE, INFORMATION);
      } else {
        alertManager.emit({
          message: `Não foi possível gerar o evento pendente de ${
            isAfastamento ? 'afastamento' : 'férias'
          } (S-2230), verifique se a empresa já enviou os eventos não periódicos (S-2200/S-2300) em: Módulo Folha de Pagamento > eSocial > Exportação > Monitor de eventos eSocial.`,
          type: AlertType.ERROR,
        });
      }
    },
  });
};

export const deletarEventosPendentes = (listaAtualizada, listaOriginal) => {
  const idsCadastrados = listaAtualizada.map(it => it.id);
  const itensExcluidos = listaOriginal.filter(it => !idsCadastrados.includes(it.id));
  if (itensExcluidos.length > 0) {
    deleteEventosPendentesESocial(
      itensExcluidos.map(it => it.id),
      {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: () => null,
      }
    );
  }
};

export const getRefValidaFolhaEnvioAutomatico = () => {
  const dataAtual = currentDate();
  const diaAtual = Number(format(dataAtual, 'DD'));
  return diaAtual > getDiaLimiteEnvioPeriodicos() ? formatRef(dataAtual) : formatRef(add(dataAtual, -1, 'month'));
};

export const getRefValida13EnvioAutomatico = () => {
  const dataAtual = currentDate();
  const anoAtual = format(dataAtual, 'YYYY');
  return `${anoAtual}12`;
};

const salvarNaoMostrarMensagemATres = (idUsuario: number) => {
  salvarUsuarioVisualizouTour(idUsuario, ITEM_TOUR_MSG_ATRES_DESCONTINUADO, {
    errorFunction: message => showToast(message, ERROR),
    thenFunction: () => null,
  });
};

export const showMensagemATresDescontinuado = (idUsuario: number) => {
  checkUsuarioVisualizouTour(idUsuario, ITEM_TOUR_MSG_ATRES_DESCONTINUADO, {
    errorFunction: message => showToast(message, ERROR),
    thenFunction: (result: boolean) => {
      if (!result) {
        alertManager.emit({
          message: MSG_ATRES_DESCONTINUADO,
          onNoClick: () => salvarNaoMostrarMensagemATres(idUsuario),
          onOkClick: () => {
            window.open('https://www.calimaerp.com/certificado_digital', '_blank');
            salvarNaoMostrarMensagemATres(idUsuario);
          },
          textWarningNo: 'Continuar mesmo assim',
          textWarningYes: 'Acessar site',
          type: AlertType.WARNING_YES_NO,
        });
      }
    },
  });
};

export const showMensagemATresVencido = (optionalProps?: { onNoClick?: () => void; textWarningNo?: string }) => {
  const aux: AlertProps = {
    message: (
      <>
        <p className="text-center">
          <b>O certificado A3 da empresa atual está vencido e não será possível ativar outro no Calima.</b>
        </p>
        <p className="text-center">
          Recomendamos que adquira um certificado A1 para essa empresa. Acesse o site e obtenha um certificado digital A1 com um valor diferenciado!
        </p>
      </>
    ),
    onOkClick: () => {
      window.open('https://www.calimaerp.com/certificado_digital', '_blank');
    },
    textWarningNo: 'Continuar',
    textWarningYes: 'Acessar site',
    type: AlertType.WARNING_YES_NO,
    ...(optionalProps || {}),
  };
  alertManager.emit(aux);
};

export const gerarDias = (length: number) => Array.from({ length }, (_, index) => ({ label: String(index + 1), value: index + 1 }));
